import React from "react"
import { graphql } from "gatsby";

import Layout from "../layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data }) => (
  //TODO: create 404 page data on prismic query lang from prismic
  <Layout mainClassName="responsive-paddings" lang={data.prismic404Page.lang}>
    <SEO title={data.prismic404Page.data.title} lang={data.prismic404Page.lang} />
    <h1>{data.prismic404Page.data.title}</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
    query($slug: String!){
        prismic404Page(fields: { slug: { eq: $slug } } ) {
            lang
            data {
                title
            }
        }
    }
`